import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-9 col-md-7 col-lg-4 mb-5 m-lg-0">
          <a className="navbar-brand" href="#home">
            Catalio
          </a>
          <p className="light">Teams. Projects. Information. Simplified.</p>
          <ul className="social-list blue">
            <li>
              <a href="https://www.facebook.com/dynamitedevelopment/">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/dynodev">
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/dynodev/about/">
                <i className="fab fa-linkedin-in" />
              </a>
            </li>
          </ul>
        </div>

        <div className="col-7 col-sm-6 col-md-4 col-lg-3">
          <ul className="contact-list">
            <li className="title">Contact information</li>

            <li>
              <i className="fas fa-map-marker-alt" />
              <p>708 S Main St, Houston, TX 77002</p>
            </li>
            <li>
              <i className="fas fa-envelope" />
              <a
                href="mailto:support@dynodev.io?Subject=Contact%20Form"
                target="_top"
              >
                <p> support@dynodev.io</p>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12">
          <p className="copyright light">
            © 2018 <a href="/">Dynamite Development</a>. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
