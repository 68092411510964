import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import '../style/css/main.css'
import favicon from '../images/favicon.ico'

export default function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            defaultTitle={data.site.siteMetadata.title}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
            ]}
            meta={[
              {
                name: 'description',
                content:
                  'Tired of wondering how your Salesforce features and applications were implemented in your org? Save time, increase productivity, and cultivate a greater collaboration with a central repository that tracks how and why your applications were built!',
              },
              {
                name: 'keywords',
                content:
                  'catalio, catalog, salesforce, metadata, documentation, document, governance',
              },
              {
                name: 'twitter:card',
                content: 'app',
              },
              {
                name: 'twitter:site',
                content: '@dynodev',
              },
              {
                name: 'twitter:url',
                content: 'https://www.catalio.com/',
              },
              {
                name: 'twitter:text:title',
                content: data.site.siteMetadata.title,
              },
              {
                name: 'twitter:image',
                content:
                  'https://appexchange.salesforce.com/servlet/servlet.FileDownload?file=00P3A00000eClv5UAC',
              },
              {
                name: 'twitter:description',
                content:
                  'Tired of wondering how your Salesforce features and applications were implemented in your org? Save time, increase productivity, and cultivate a greater collaboration with a central repository that tracks how and why your applications were built!',
              },
              {
                name: 'twitter:creator',
                content:
                  'Tired of wondering how your Salesforce features and applications were implemented in your org? Save time, increase productivity, and cultivate a greater collaboration with a central repository that tracks how and why your applications were built!',
              },
              {
                property: 'og:title',
                content: data.site.siteMetadata.title,
              },
              {
                property: 'og:creator',
                content: '@dynodev',
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:url',
                content: 'https://www.catalio.com/',
              },
              {
                property: 'og:image',
                content:
                  'https://appexchange.salesforce.com/servlet/servlet.FileDownload?file=00P3A00000eClv5UAC',
              },
              {
                property: 'og:description',
                content:
                  'Tired of wondering how your Salesforce features and applications were implemented in your org? Save time, increase productivity, and cultivate a greater collaboration with a central repository that tracks how and why your applications were built!',
              },
            ]}
          ></Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
          <Footer siteTitle={data.site.siteMetadata.title} />
        </>
      )}
    />
  )
}
