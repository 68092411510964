import React from 'react'
import logo from '../images/web_logo.png'

const Header = ({ siteTitle }) => (
  <header>
    <nav className="navbar fixed-top navbar-expand-lg navbar-light">
      <div className="container">
        <a className="navbar-brand nav-item nav-link" href="/">
          <img src={logo} alt="Logo" width="150px" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto text-right">
            <a className="nav-item nav-link active" href="#home">
              Home <span className="sr-only">(current)</span>
            </a>
            <a className="nav-item nav-link" href="#about">
              About
            </a>
            <a className="nav-item nav-link" href="#features">
              Features
            </a>
            <a className="nav-item nav-link" href="#faq">
              Faq
            </a>
            <a className="nav-item nav-link" href="https://blog.catalio.com/">
              Blog
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

export default Header
